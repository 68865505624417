<script>
import Layout from "../../layouts/main";
import _ from 'lodash';
import moment from "moment";
import { FETCH_NOTIFICATIONS, MARK_NOTIFICATION_READ } from "@/state/actions.type";

export default {
    components: {
        Layout
    },
    data() {
        return {
            page: 1,
            pagesize: 20,
            notificationList: [],
            fetchingNotifications: false
        }
    },
    computed: {
        notifications() {
            return this.$store.state.notifications.notificationsPageContent;
        },
    },
    filters: {
        date(date) {
            return moment(date).format("MMM DD, YYYY HH:mm A");
        }
    },
    created() {
        this.fetchNotifications(true);
    },
    methods: {
        fetchNotifications(reset = false) {
            this.fetchingNotifications = true;

            let payload = {
                page: this.page,
                pagesize: this.pagesize,
                filters: {},
                notificationsPage: true,
                reset: reset
            };

            this.$store.dispatch(`notifications/${FETCH_NOTIFICATIONS}`, payload)
            .then(() => {
                this.notificationList = _.groupBy(this.notifications, function (item) {
                    return moment(item.created_at).startOf('day').format('MMMM DD, YYYY');
                });
            }).catch((error) => {
                const err = JSON.parse(error.message);
                this.$notify({
                    type: "error",
                    text: err.message,
                    closeOnClick: true,
                });
            })
            .finally(() => this.fetchingNotifications = false);
        },
        loadMoreNotifications(el) {
            if((Math.ceil(el.srcElement.offsetHeight) + Math.ceil(el.srcElement.scrollTop)) >= el.srcElement.scrollHeight) {
                if(this.$store.getters['notifications/moreNotifications']) {
                    this.page++;
                    this.fetchNotifications();
                }
            }
        },
        async openTransactionDetails(item) {
            if(!item.read) {
                await this.$store.dispatch(`notifications/${MARK_NOTIFICATION_READ}`, item.uuid).then(() => {
                this.fetchNotifications()
                });
            }
            this.$router.push(`/transactions/payments/payment-details?order_ref=${item.metadata.portone_order_ref}`);
        },
    }
}
</script>

<template>
  <Layout>
    <div class="d-flex" style="height:80vh; overflow-y: auto; flex-direction: column;"  @scroll="loadMoreNotifications">
        <div v-if="notifications.length > 0">
            <div v-for="(items, key) in notificationList" :key="key">
                <h4 class="date-title mb-0 mt-2">{{key}}</h4>
                <hr class="my-2">
                <div v-for="(notification, index) in items" :key="index" class="d-flex justify-content-between">
                    <div class="d-flex my-1">
                        <img src="@/assets/images/success_check.svg" />
                        <!-- <p class="message ml-3 my-auto">{{ notification.metadata.message }}</p> -->
                        <b-card-text @click="openTransactionDetails(notification)" style="cursor: pointer;" class="message ml-3 my-auto">
                            {{ notification.metadata.message ? notification.metadata.message : notification.metadata.merchant_order_ref }}
                        </b-card-text>
                    </div>
                    <p class="my-auto">{{ notification.created_at | date }}</p>
                </div>
            </div>
            <div class="d-flex justify-content-center" v-if="fetchingNotifications" >
                <b-spinner label="Loading..."></b-spinner>
            </div>
            <div class="p-3 pb-4 my-auto" v-show="!$store.getters['notifications/moreNotifications']">
                <b-card-text class="orange-theme-text text-center h5">That's all your notifications from the last 30 days.</b-card-text>
            </div>
        </div>
        <div class="m-auto" v-else>
            <b-card-text class="orange-theme-text text-center h5">You have no notifications</b-card-text>
        </div>
    </div>
  </Layout>
</template>

<style scoped>
.date-title {
    color: #000;
    /* font-family: Gotham; */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}
.message {
    color: rgba(0, 0, 0, 0.50);
    /* font-family: Gotham; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
}
.notification-date {
  color: #7b7a79
}

.orange-theme-text {
  color: #fc6d31;
}
</style>